//------------------------------------------------------------------------
// Styles for custom headings and labels used throughout the project.
//
// Each of these should also be turned into a helper class in _font-helpers.scss
//
// For rich text heading styles (e.g. h2–h6), see _rich-text-headings.scss
//------------------------------------------------------------------------
@mixin body-copy-size() {
  @include fs-scale(font-size, (360px: $body-copy-min-size, 600px: $body-copy-max-size));
}

@mixin intro-text-size() {
  @include fs-scale(font-size, (360px: 20px, 600px: 24px));
  line-height: 1.4 !important;
}

// Park and Species page title
@mixin page-title {
  @include font-klinic;
  @include fs-scale(font-size, (320px: 42px, 900px: 58px));
  font-weight: $klinic-bold;
  line-height: 1.1;
}

@mixin section-heading {
  @include font-klinic;
  @include fs-scale(font-size, (320px: 28px, 900px: 32px));
  font-weight: normal;
  line-height: 1.2;
}
