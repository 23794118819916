//------------------------------------------------------------------------
// Park page layout and component styling
//------------------------------------------------------------------------
$park-background-horz-offset: 20px;

@keyframes fadeSlideIn {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-$park-background-horz-offset);
  }
}

.Park {
  $primary-col-width: 56.25%;
  $row-bp: 768px;
  $share-top-bp: 1024px;
  $carousel-float-bp: 1200px;
  $carousel-horz-offset: 50px;
  overflow-x: hidden;

  @include fs-min-width($row-bp) {
    align-items: stretch;
    display: flex;
    min-height: 100%;
  }

  &-primary {
    // We’re using the l-padding class but need to reset it when in 2-col layout
    @include fs-scale(padding-left, ($row-bp: 10px, 1260px: 50px), $initial: false);
    @include fs-scale(padding-right, ($row-bp: 10px, 1260px: 40px), $initial: false);
    background-color: fs-color('green');
    color: #fff;
    overflow: hidden;// prevent park icon from causing horizontal scrolling in mobile
    position: relative;

    // 1. IE 11 has a bug with min-height on flex container so we have to apply to child
    @include fs-min-width($row-bp) {
      flex: 1 0 $primary-col-width;
      min-height: calc(100vh - #{fs-rem($layout-header-height-desktop)});// [1]
    }

    @include fs-min-width($carousel-float-bp) {
      padding-right: fs-rem($carousel-horz-offset);
    }

    // Color overlay
    &:before {
      @include fs-absolute-fill;
      content: '';
      opacity: 0.7;
      z-index: 2;// above bg image

      @supports (mix-blend-mode: multiply) {
        mix-blend-mode: multiply;
      }
    }
  }// end primary

  &-map {
    z-index: 1;
  }// end map

  &-secondary {
    position: relative;
    width: 100%;

    @include fs-min-width($row-bp) {
      display: flex;
      flex-direction: column;
    }
  }// end secondary

  &-background {
    overflow: hidden;
    width: calc(100% + 20px);
    z-index: 1;

    @include fs-max-width($row-bp - 1px) {
      @include fs-aspect-ratio(1);
    }

    @include fs-min-width($row-bp) {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
    }

    &-image {
      $carsouel-speed: 1000ms;
      @include fs-absolute-fill;
      opacity: 0;
      z-index: 1;

      .no-js & {
        &:first-child {
          opacity: 1;
          z-index: 2;
        }
      }

      &.is-active {
        animation: $carsouel-speed fadeSlideIn fs-easing('easeOutCubic') forwards;
        z-index: 2;
      }

      img {
        @include fs-absolute-fill;
        object-fit: cover;
      }
    }// end image
  }// end background

  &-icon {
    color: fs-color('black');
    opacity: 0.62;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(15%, -15%);
    z-index: 3;// above park bg image

    @include fs-min-width($row-bp) {
      left: 0;
      top: 50%;
      transform: translate(-20%, -50%);
    }

    svg {
      stroke: currentColor;
      width: fs-rem(450px);

      pattern {
        stroke-width: 0;
      }
    }
  }// end icon

  &-content {
    @include fs-scale(padding-top, (320px: 20px, 900px: 30px));
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: fs-rem(30px);
    position: relative;
    z-index: 4;// above bg image and park icon
  }// end content

  &-stats {
    // $stats-row-bp: 500px;
    $stats-row-bp: 320px;
    order: 4;

    @include fs-min-width($stats-row-bp) {
      display: flex;
    }

    @include fs-max-width($row-bp - 1px) {
      margin-bottom: fs-rem(30px);
    }

    @include fs-min-width($row-bp) {
      margin-bottom: auto;// push other content to the bottom
      order: 1;
    }

    @include fs-min-width($share-top-bp) {
      padding-right: fs-rem($layout-share-link-size + 30px);
    }

    &-item {
      @include fs-font-smoothing;

      @include fs-max-width($stats-row-bp - 1px) {
        margin-bottom: fs-rem(20px);
        text-align: center;
      }

      @include fs-min-width($stats-row-bp) {
        flex: 1 1 33.33%;
        margin-right: fs-rem(10px);

        // This is specific to the exact content
        &:last-child {
          margin-right: 0;
        }
      }
    }// end item

    &-number {
      @include fs-scale(font-size, (320px: 40px, 600px: 48px));
      @include fs-scale(font-size, ($row-bp: 36px, 1024px: 48px), $initial: false);
      display: block;
      font-style: italic;
      font-weight: $klinic-light;
      line-height: 1;
      margin-bottom: fs-rem(5px);
      position: relative;

      @include fs-min-width($stats-row-bp) {
        padding-top: fs-rem(5px);
      }

      // Horz rule
      &:before {
        border-top: 2px solid currentColor;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: fs-rem(24px);

        @include fs-max-width($stats-row-bp - 1px) {
          display: none;
        }
      }
    }// end number

    &-text {
      // @include fs-scale(font-size, (320px: 14px, 540px: 16px));
      // @include fs-scale(font-size, ($row-bp: 15px, 1024px: 16px), $initial: false);
      display: inline-block;// enables line-height since span is inline by default
      font-size: fs-rem(16px);
      line-height: 1.1;

      @include fs-max-width($layout-small-bp - 1px) {
        opacity: 0.8;
        font-weight: bold;
      }

      @include fs-min-width($layout-small-bp) {
        opacity: 0.63;
      }

      @include fs-max-width($stats-row-bp - 1px) {
        br {
          display: none;
        }
      }
    }// end text
  }// end stats

  &-states {
    $circle-size: 40px;
    display: flex;
    margin-bottom: fs-rem(8px);
    order: 2;

    @include fs-min-width($row-bp) {
      margin-top: fs-rem(40px);
    }

    &-item {
      border: 1px solid currentColor;
      border-radius: 50%;
      display: block;// element is a span
      height: fs-rem($circle-size);
      margin-right: fs-rem(10px);
      position: relative;
      width: fs-rem($circle-size);
    }// end item

    &-text {
      @include fs-font-smoothing;
      font-size: fs-rem(14px);
      left: 50%;
      letter-spacing: 2px;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translate(-50%, -50%);
    }// end text
  }// end states

  &-title {
    margin-bottom: fs-rem(36px);
    order: 3;

    @include fs-min-width($carousel-float-bp) {
      padding-right: fs-rem(20px);
    }
  }// end title

  &-species {
    $species-selector: &;
    $species-row-bp: 460px;
    $species-gutter: 30px;
    $species-vert-margin: 30px;
    $img-border-width: 6px;
    margin-bottom: fs-rem(60px);
    order: 5;

    @include fs-min-width($carousel-float-bp) {
      margin-bottom: fs-rem(30px);
      padding-right: fs-rem(20px);
    }

    &-heading {
      @include fs-font-smoothing;
      font-size: fs-rem(14px);
      font-weight: $klinic-bold;
      letter-spacing: fs-em(2px, 14px);
      line-height: 1;
      margin-bottom: fs-rem(40px);
      padding-top: fs-rem(16px);
      position: relative;
      text-transform: uppercase;

      @include fs-min-width($species-row-bp) {
        margin-bottom: fs-rem(21px);
      }

      &:before {
        border-top: 2px solid currentColor;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: fs-rem(30px);
      }
    }// end heading

    &-list {
      @include fs-min-width($species-row-bp) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: fs-rem(-$species-vert-margin);
        margin-left: fs-rem(-$species-gutter);
      }
    }// end list

    &-item {
      position: relative;// for link overlay

      @include fs-max-width($species-row-bp - 1px) {
        margin-bottom: fs-rem(75px);
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 80%;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include fs-min-width($species-row-bp) {
        display: flex;
        flex-direction: column;
        margin-bottom: fs-rem($species-vert-margin);
        padding-left: fs-rem($species-gutter);
        width: 33.33%;
      }
    }// end item

    &-link {
      @include fs-font-smoothing;
      color: #fff;
      display: block;
      font-size: fs-rem(20px);
      font-weight: $klinic-bold;
      margin-bottom: fs-rem(5px);

      @include fs-min-width($species-row-bp) {
        font-size: fs-rem(16px);
      }

      &:after {
        @include fs-absolute-fill;
        content: '';
        z-index: 1;// overlay image thumbnail
      }

      @include fs-attention {
        & ~ #{$species-selector}-image {
          transform: scale(1.07);
        }
      }
    }// end link

    &-year {
      font-size: fs-rem(18px);
      font-style: italic;
      margin-bottom: fs-rem(20px);
      white-space: nowrap;

      @include fs-min-width($species-row-bp) {
        font-size: fs-rem(15px);
      }
    }// end year

    &-image {
      $size: 160px;
      border: $img-border-width solid #fff;
      border-radius: 50%;
      display: block;
      height: fs-rem($size);
      position: relative;
      transition: all 150ms ease-in-out;
      width: fs-rem($size);

      @include fs-max-width($species-row-bp - 1px) {
        margin-left: auto;
        margin-right: auto;
      }

      @include fs-min-width($species-row-bp) {
        $size: 100px;
        height: fs-rem($size);
        margin-top: auto;// bottom align
        width: fs-rem($size);
      }

      img {
        border-radius: 50%;
      }

      // extra border
      &:after {
        $border-width: 1px;
        background-color: rgba(#fff, 0.2);
        border-radius: 50%;
        bottom: fs-rem(-$border-width - $img-border-width);
        content: '';
        left: fs-rem(-$border-width - $img-border-width);
        position: absolute;
        right: fs-rem(-$border-width - $img-border-width);
        top: fs-rem(-$border-width - $img-border-width);
        z-index: -1;
      }
    }// end image

    &-icon {
      $size: 40px;
      background-color: fs-color('black');
      border-radius: 50%;
      height: fs-rem($size);
      position: absolute;
      top: 0;
      right: fs-rem(-10px - $img-border-width);
      width: fs-rem($size);

      @include fs-min-width($species-row-bp) {
        $size: 30px;
        height: fs-rem($size);
        width: fs-rem($size);
      }

      svg {
        $icon-size: 20px;
        fill: #fff;
        height: auto;
        left: 50%;
        max-height: fs-rem($icon-size);
        max-width: fs-rem($icon-size);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;

        @include fs-min-width($species-row-bp) {
          $icon-size: 17px;
          max-height: fs-rem($icon-size);
          max-width: fs-rem($icon-size);
        }
      }
    }// end icon
  }// end species

  &-mapLink {
    @include fs-font-smoothing;
    align-self: center;
    color: #fff;
    display: inline-block;
    font-size: fs-rem(16px);
    font-weight: $montserrat-bold;
    opacity: 0.7;
    order: 6;
    text-transform: uppercase;

    @include fs-attention {
      opacity: 1;
    }

    @include fs-min-width($row-bp) {
      font-size: fs-rem(13px);
      margin-right: 0;
    }

    .icon {
      fill: none;
      stroke: currentColor;
      stroke-width: 4px;
      vertical-align: middle;
    }
  }// end mapLink

  .Share {
    margin-bottom: fs-rem(40px);
    order: 4;
    position: relative;
    z-index: 5;// above content

    @include fs-min-width($share-top-bp) {
      position: absolute;
      right: fs-rem(0);
      top: fs-rem(30px);
    }

    &-list {
      @include fs-max-width($share-top-bp - 1px) {
        display: flex;
      }
    }// end list

    &-item {
      @include fs-max-width($share-top-bp - 1px) {
        margin-right: fs-rem(15px);
      }
    }// end item

    // @include fs-min-width($row-bp) {
    //   position: absolute;
    //   right: fs-rem(40px);
    //   top: fs-rem(40px);
    // }
  }// end share

  &-carousel {
    $padding-top: 24px;
    $button-size: 32px;
    $horz-padding: 27px;// Adjust so that “Learn more about the Endangered Species Act” fits on one line
    background-color: fs-color('offwhite');
    padding-top: fs-rem($padding-top + $button-size + 26px);
    position: relative;
    z-index: 2;// above bg image

    @include fs-min-width($row-bp) {
      margin-top: auto;
    }

    @include fs-min-width($carousel-float-bp) {
      bottom: fs-rem(60px);
      left: fs-rem(-$carousel-horz-offset);
      max-width: fs-rem(365px);
    }

    &-item {
      overflow: hidden;
      padding-bottom: fs-rem(35px);
      width: 100%;

      &.has-cta {
        padding-bottom: 0;
      }

      &-wrap {
        &:not(:last-child) {
          margin-bottom: fs-rem(30px);
        }

        @include fs-min-width($carousel-float-bp) {
          padding-left: fs-rem($horz-padding);
          padding-right: fs-rem($horz-padding);
        }
      }// end wrap
    }// end item

    // Park carousel changes height for each item,
    // so use transition to animate it.
    // https://flickity.metafizzy.co/options.html#adaptiveheight
    .flickity-viewport {
      transition: height 400ms fs-easing('easeOutCubic');
    }

    .flickity-button {
      $icon-horz-offset: 1px;// visually center icons
      background-color: transparent;
      border: 1px solid currentColor;
      border-radius: 50%;
      color: $c-default;
      height: fs-rem($button-size);
      position: absolute;
      top: fs-rem($padding-top);
      width: fs-rem($button-size);

      svg {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: fs-rem(10px);
      }

      &.previous {
        margin-left: fs-rem($horz-padding);

        svg {
          margin-left: fs-rem($icon-horz-offset);
        }
      }

      &.next {
        margin-left: fs-rem($button-size + $horz-padding + 10px);

        svg {
          margin-left: fs-rem(-$icon-horz-offset);
        }
      }
    }

    .flickity-page-dots {
      bottom: auto;
      counter-reset: carousel;
      font-size: fs-rem(16px);
      font-style: italic;
      font-weight: $montserrat-bold;
      left: auto;
      opacity: 0.4;
      padding-left: 1em;
      right: 0;
      top: fs-rem($padding-top);
      width: auto;

      @include fs-min-width($carousel-float-bp) {
        padding-right: fs-rem($horz-padding);
      }

      &:after {
        content: "/" counter(carousel);
      }

      .dot {
        counter-increment: carousel;
        font-size: 1em;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
        width: 0;

        &:after {
          content: counter(carousel);
        }

        &[aria-current] {
          visibility: visible;
        }
      }
    }

    &-heading {
      font-size: fs-rem(24px);
      line-height: 1.15;
      margin-bottom: fs-rem(10px);
    }// end heading

    &-content {
    }// end content

    &-cta {
      padding: fs-rem(20px);
      width: 100%;
    }// end cta
  }// end carousel

}// end Park
