//------------------------------------------------------------------------
// Park stats card (used in homepage carousel)
//------------------------------------------------------------------------
.ParkCard {
  $wide-bp: 1150px;
  overflow: hidden;// prevent park icon from overflowing
  padding: fs-rem(30px);
  position: relative;
  width: 100%;

  .flickity-enabled.is-ready & {
    height: 100%;
    min-height: fs-rem(260px);
    padding-bottom: fs-rem(50px);
  }

  @include fs-min-width($wide-bp) {
    padding: fs-rem(45px 50px);
  }

  &:before {
    @include fs-absolute-fill;
    content: '';
    opacity: 0.7;
    z-index: 2;// above bg image

    @supports (mix-blend-mode: multiply) {
      mix-blend-mode: multiply;
    }
  }

  &-background {
    z-index: 1;
  }// end background

  &-icon {
    bottom: 0;
    opacity: 0.71;
    position: absolute;
    right: 0;
    transform: translate(20%, 30%);
    z-index: 3;// above bg image

    svg {
      stroke: fs-color('black');
      width: fs-rem(250px);
    }
  }// end icon

  &-content {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    z-index: 4;
  }// end content

  &-label {
    @include fs-font-smoothing;
    font-size: fs-rem(16px);
    font-style: italic;
    font-weight: $klinic-bold;
    margin-bottom: fs-rem(5px);
  }// end label

  &-name {
    font-size: fs-rem(27px);
    font-weight: $klinic-bold;
    line-height: 1;
    margin-bottom: fs-rem(28px);
  }// end name

  &-link {
    color: #fff;
    display: inline-block;

    .icon {
      fill: none;
      margin-left: fs-rem(5px);
      transform: translateX(-2px);
      transition: transform 150ms ease-in-out;
    }// end icon

    @include fs-attention {
      .icon {
        transform: translateX(0);
      }
    }
  }// end link

  &-stats {
    display: flex;
    margin-top: auto;// bottom align
  }// end stats

  &-stat {
    width: 50%;

    &-number {
      @include fs-font-smoothing;
      display: block;
      font-size: fs-rem(26px);
      font-weight: $montserrat-bold;
      line-height: 1;
      margin-bottom: fs-rem(5px);
      position: relative;
      padding-top: fs-rem(10px);

      // Horz rule
      &:before {
        border-top: 2px solid currentColor;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: fs-rem(24px);
      }
    }// end number

    &-text {
      font-size: fs-rem(16px);
      line-height: 1.1;
      opacity: 0.63;
    }// end text

  }// end stats

}// end ParkCard
