// -------------------------------------------------------------------------
// Base Styles
// -------------------------------------------------------------------------
// Set accent color
// https://web.dev/articles/accent-color
:root {
  accent-color: fs-color('lightGreen');
}

// 1. Set bg color on html instead of body to ensure it always fills the page
//    http://css-tricks.com/just-one-of-those-weird-things-about-css-background-on-body/
// 2. Only supported in Chrome, Firefox, Opera, and Chrome for Android as of January 2018
//    https://caniuse.com/#feat=css-scroll-behavior
html {
  background-color: $bg-default;// [1]
  color: $c-default;
  font-family: $klinic, $klinic-fallback;
  scroll-behavior: smooth;// [2]

  &.js {
    overflow: hidden;// hide scrollbars in Windows
  }

  // Allow long words to wrap
  @include fs-max-width(319px) {
    @include fs-break-word;
  }
}

// 1. Prevent horizontal scrolling (could happen if authors include a large fixed-width embed)
//    Note: Remove this if using “position: sticky” as it will break it.
body {
  overflow-x: hidden;// [1]
}

a {
  @include link-base;
}

// Apply default transition effect to icons in links or buttons
[data-icon] {
  .icon {
    fill: currentColor;
    display: inline-block;
    transform: translateX(0);
    transition: transform 150ms ease-in-out;
  }

  @include button-attention {
    .icon {
      transform: translateX(1px);

      &-download {
        transform: scale(1.06);
      }
    }
  }
}

// SVGs without a class or inline “fill” attribute should inherit fill color
svg:where(:not([fill], [class])) {
  fill: currentColor;
}

// Prevent zoom on iOS (requires adding "ios" class to <html>)
// http://www.456bereastreet.com/archive/2012133.33%ios_webkit_browsers_and_auto-zooming_form_controls/
// sass-lint:disable no-important
// scss-lint:disable ImportantRule
.ua-ios {
  input,
  textarea,
  select {
    font-size: 1rem !important;
  }
}

// -------------------------------------------------------------------------
// Remove text-shadow in selection highlight: http://h5bp.com/i
// These selection rule sets have to be separate.
//
// Won't work on bullets, text inputs, or textareas
// http://stackoverflow.com/a/122089133.33%673457
// -------------------------------------------------------------------------
// $text-highlight-color: lightBlue;
//
// ::-moz-selection {
//   background-color: $text-highlight-color;
//   text-shadow: none;
// }
//
// ::selection {
//   background-color: $text-highlight-color;
//   text-shadow: none;
// }

//------------------------------------------------------------------------
// Hide outlines on focused elements using “what-input” JS plugin
//------------------------------------------------------------------------
[data-whatinput='mouse'],
[data-whatinput='touch'] {
  *:focus {
    outline: none;
  }
}

//------------------------------------------------------------------------
// Disable hover event while scrolling by adding this class (see events.js)
// https://www.thecssninja.com/javascript/pointer-events-60fps
//------------------------------------------------------------------------
// .disable-hover,
// .disable-hover * {
//   pointer-events: none !important;
// }
