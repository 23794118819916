// -------------------------------------------------------------------------
// Color variables
//
// Use variable names that describe the color. Avoid additional abstraction
// unless absolutely necessary (e.g. themeing)
// http://bensmithett.com/stop-using-so-many-sass-variables/
//
// Can’t think of another name for a similar color? Try this site:
// http://chir.ag/projects/name-that-color/
// -------------------------------------------------------------------------

// NOTE: Using Sass maps allows us to programmatically generate helper classes.
//       If this isn’t needed, leave blank and use regular Sass vars instead.

// Background and brand colors (optional, see note above)
// Reference these colors with `fs-color('colorName')`
$fs-colors: (
  'base': (
    'black': #141414,
    'blue': #3a4e59,
    'brown': #473d34,
    'green': #374434,
    'offwhite': #fdfaeb,
    'lightGreen': #5d7256,
    'orange': #dc841f,
  )
);

// Park map background image overlay colors
$overlay-saturation: 60%;
$overlay-lightness: 12%;
$overlay-colors: (
  'overlay': (
    'blue': hsl(hue(fs-color('blue')), $overlay-saturation, $overlay-lightness),
    'brown': hsl(hue(fs-color('brown')), $overlay-saturation, $overlay-lightness),
    'green': hsl(hue(fs-color('green')), $overlay-saturation, $overlay-lightness),
  )
);
// Merge overlay colors with main $fs-colors map.
$fs-colors: map-merge($fs-colors, $overlay-colors);


// Text colors (optional, see note above)
// Make sure all color combos meet WCAG contrast requirements
// - http://jxnblk.com/colorable/demos/text/
// - http://contrast-grid.eightshapes.com
// Reference these colors with `fs-color('text', 'colorName')`
$text-colors: (
  'text': (
    'black': #323232,
    'green': #4c7f40
  )
);

// Merge text colors with main $fs-colors map.
// This is required for the Frontline color mixin to work.
$fs-colors: map-merge($fs-colors, $text-colors);

// Set default color variables
//----------------------------
$bg-default: fs-color('black');
$c-default: #3a3a3a;

// Note: It's typically better to use #000 on mobile devices instead of gray
//       for better contrast. If you do that, use a mixin like this:
//
// @mixin default-text-color {
//   color: #000;
//
//   @include fs-min-width(640px) {
//     color: fs-color('text', 'darkGray');
//   }
// }
