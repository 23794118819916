//------------------------------------------------------------------------
// CTA button with map texture background
//
// NOTE: This is its own component since it’s more complex than a typical button
//------------------------------------------------------------------------
.ActionButton {
  $bg-color: fs-color('orange');
  @include fs-font-smoothing;
  background-color: $bg-color;
  color: #fff;
  display: block;
  font-weight: $montserrat-bold;
  overflow: hidden;
  padding: fs-rem(30px);
  position: relative;
  text-align: center;

  @include fs-attention {
    background-color: darken($bg-color, 5%);
  }

  // 1. Fix issues with nested span from data-orphans breaking
  // text underline and not preventing icon from wrapping
  &-text {
    display: inline-block;// [1]
    position: relative;
    text-decoration: inherit;// [1]
    z-index: 2;// above background

    .u-nowrap {
      display: inline-block;// [1]
      text-decoration: inherit;// [1]
    }
  }// end text

  &-icon {
    stroke: currentColor;
    stroke-width: 3px;
    vertical-align: middle;
  }// end icon

  &-background {
    height: auto;
    left: 50%;
    opacity: 0.05;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 140%;// make big enough so park icon border doesn’t show
    z-index: 1;

    svg {
      stroke: #000;
      width: fs-rem(100px);
    }
  }// end background

}// end ActionButton
