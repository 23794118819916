//------------------------------------------------------------------------
// Default form styles
//
// sass-lint:disable no-important
// scss-lint:disable ImportantRule
//------------------------------------------------------------------------
.u-form {
  $bp-columns: 580px;
  --col-gap: #{fs-rem(20px)};

  &-row {
    &:has(.u-form-col) {
      @include fs-min-width($bp-columns) {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        column-gap: var(--col-gap);
        justify-content: space-between;
      }
    }
  }// end row

  &-col {
    @include fs-min-width($bp-columns) {
      width: calc(50% - (var(--col-gap) / 2));
    }
  }// end col

  // Wrapper around field and label for positioning the error message
  &-field {
    margin-bottom: fs-rem(24px);
    position: relative;
  }// end field

  label {
    font-family: $montserrat;
    font-size: fs-rem(16px);
    line-height: 1.1;

    .required {
      color: $input-error-color;
    }
  }

  // Apply default styles to all text inputs types
  @include fs-text-inputs {
    @include input-default;
  }

  textarea {
    @include input-default;
  }

  // Apply error styles to all form elements
  datalist,
  input,
  select,
  textarea {
    &.is-invalid {
      @include input-error;
    }
  }

  fieldset + fieldset {
    margin-top: fs-rem(30px);
  }

  legend {
    font-family: $montserrat;
    font-size: fs-rem(16px);
    margin-bottom: 1rem;
  }

  &-checkbox {
    align-items: flex-start;
    display: flex;
    column-gap: fs-rem(6px);

    &:not(:last-child) {
      margin-bottom: fs-rem(20px);
    }

    input[type="checkbox"] {
      margin-top: fs-rem(2px);
    }
  }// end checkbox

  // Error messages
  [data-validate='error'] {
    color: $input-error-color;
    font-size: fs-rem(15px);
    left: 0;
    margin-top: fs-rem(5px);
    position: absolute;
    top: 100%;

    &.is-hidden {
      display: none !important;
    }
  }// end error
}// end u-form
