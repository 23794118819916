//------------------------------------------------------------------------
// Font style helper classes
//
// sass-lint:disable no-important
// scss-lint:disable ImportantRule
//------------------------------------------------------------------------
.f {

  &-uppercase {
    text-transform: uppercase;
  }

  // &-smallcaps {
  //   font-variant: small-caps;
  // }

  // &-old-style-figures {
  //   font-feature-settings: "lnum" off, "onum";// use Old-Style figures
  // }// end old-style-figures

  &-lining-figures {
    font-feature-settings: "lnum", "onum" off;// use lining figures
  }// end lining-figures

  // Scale the body copy font-size relative to the viewport width
  // Note: This won’t affect h1–h6, which are set in rems (see _rich-text-headings.scss).
  &-scale-body {
    @include body-copy-size;
  }

  &-scale-intro {
    @include intro-text-size;
  }

  // Web fonts
  // Depends on mixins in _font-mixins.scss
  //---------------------------------
  &-klinic {
    @include font-klinic;
  }

  &-montserrat {
    @include font-montserrat;
  }

  // Custom styles
  //---------------------------------
  &-title {
    @include page-title;
  }

  &-section {
    @include section-heading;
  }

}// end f
