//------------------------------------------------------------------------
// Rich text heading styles
//
// <h1> should be reserved for the page title (see _custom-text-styles.scss).
// We are automatically stepping headings down a level in markdown.rb,
// but if an <h1> somehow makes it into a rich-text field, it will be styled
// to look like an <h2> (see _richtext.scss)
//
// Use fs-scale() to override “f-scale-body” styles for body copy
// since headings typically need to scale at different rates.
//------------------------------------------------------------------------

// h2
//---------------------------------
@mixin h2 {
  @include fs-scale(font-size, (320px: 30px, 900px: 40px));
  font-weight: bold;
  line-height: 1.2;
}

// h3
//---------------------------------
@mixin h3 {
  @include fs-scale(font-size, (320px: 20px, 900px: 22px));
  font-weight: bold;
  line-height: 1.24;
}

// h4
//---------------------------------
@mixin h4 {
  @include fs-scale(font-size, (320px: 18px, 900px: 28px));
  font-weight: bold;
  line-height: 1.333;
}

// h5
//---------------------------------
@mixin h5 {
  @include fs-scale(font-size, (320px: 16px, 900px: 22px));
  font-weight: bold;
  line-height: 1.2;
}

// h6
//---------------------------------
@mixin h6 {
  @include fs-scale(font-size, (320px: 16px, 900px: 18px));
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
}
