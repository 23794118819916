//------------------------------------------------------------------------
// Header (fixed to bottom in desktop view)
//------------------------------------------------------------------------
.Header {
  $min-horz-padding: 17px;
  $max-horz-padding: 34px;
  $border-color: rgba(#fff, 0.5);
  @include fs-scale(padding-left padding-right, (320px: $min-horz-padding, $layout-header-bp: $max-horz-padding));
  align-items: center;
  background-color: $bg-default;
  color: #fff;
  display: flex;
  height: fs-rem($layout-header-height-mobile);
  justify-content: space-between;
  position: relative;
  z-index: fs-zindex('header');

  .no-js & {
    flex-wrap: wrap;
  }

  @include fs-max-width($layout-header-bp - 1px) {
    border-bottom: 1px solid $border-color;

    .no-js & {
      height: auto;
    }
  }

  @include fs-min-width($layout-header-bp) {
    bottom: 0;
    height: fs-rem($layout-header-height-desktop);
    left: 0;
    position: fixed;
    width: 100%;

    // Shadow
    // &:before {
    //   background-image: linear-gradient(to top, rgba($bg-default, 0.3), rgba(#000, 0));
    //   bottom: 100%;
    //   content: '';
    //   height: 14px;
    //   left: 0;
    //   position: absolute;
    //   width: 100%;
    // }
  }

  &-logo {
    @include fs-min-width($layout-header-bp) {
      flex-shrink: 0;
    }

    &-link {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }// end link

    &-img {
      svg {
        @include fs-scale(margin-right, (320px: 15px, $layout-header-bp: 22px));
        fill: currentColor;
        width: fs-rem(38px);
      }
    }// end img

    &-text {
      @include fs-scale(font-size, ($layout-header-bp: 16px, 1080px: 21px));
      @include fs-font-smoothing;
      font-size: fs-rem(18px);
      font-weight: $klinic-bold;
      line-height: 1;
      opacity: 0.76;
    }// end text
  }// end logo

  a {
    color: #fff;
  }

  &-navToggle {
    $toggle-selector: &;
    @include fs-scale(padding-left padding-right, (320px: $min-horz-padding, $layout-header-bp: $max-horz-padding));
    background-color: transparent;
    border: 0;
    color: #fff;
    display: block;
    height: 100%;
    margin-left: auto;// align right
    position: absolute;
    right: 0;
    top: 0;

    @include button-attention {
      background-color: rgba(#fff, 0.5);
    }

    @include fs-min-width($layout-header-bp) {
      display: none;
    }

    &-icon {
      @include fs-burger(
        $color: currentColor,
        $gutter: 6px,
        $height: 3px,
        $transition-duration: 200ms,
        $width: 26px
      );
      display: inline-block;
    }// end icon

    &[aria-expanded="true"] {
      #{$toggle-selector}-icon {
        // This must be called after fs-burger()
        @include fs-burger-to-cross;
      }
    }
  }// end navToggle
}// end Header
