//------------------------------------------------------------------------
// Main nav
//------------------------------------------------------------------------
.Nav {
  $selector: &;
  $mobile-padding: 30px;
  $mobile-vert-margin: 30px;
  color: #fff;

  // Don’t add vertical padding to main wrapper as it will mess up expand/collapse transition
  @include fs-max-width($layout-header-bp - 1px) {
    @include expandable();
    background-color: $bg-default;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: fs-zindex('nav');

    .js & {
      position: absolute;
    }
  }

  @include fs-min-width($layout-header-bp) {
    flex-grow: 1;
  }

  &-list {
    @include fs-max-width($layout-header-bp - 1px) {
      overflow: hidden;// clearfix
      padding: fs-rem($mobile-padding);
    }

    @include fs-min-width($layout-header-bp) {
      align-items: center;
      display: flex;
      width: 100%;
    }
  }// end list

  &-item {
    $item-selector: &;
    $divider-height: 52px;

    @include fs-min-width($layout-header-bp) {
      text-align: center;
    }

    &:first-child {
      margin-left: auto;
    }

    &--primary {
      &:not(:first-child) {
        @include fs-max-width($layout-header-bp - 1px) {
          border-top: 1px solid rgba(#fff, 0.4);
          margin-top: fs-rem($mobile-vert-margin);
          padding-top: fs-rem($mobile-vert-margin);
        }

        @include fs-media((max-width: $layout-header-bp, max-height: 640px)) {
          margin-top: fs-rem(20px);
          padding-top: fs-rem(20px);
        }

        @include fs-min-width($layout-header-bp) {
          $gutter: 25px;
          margin-left: fs-rem($gutter);
          padding-left: fs-rem($gutter);
          position: relative;

          &:before {
            border-left: 1px solid currentColor;
            content: '';
            height: 100%;
            left: 0;
            opacity: 0.3;
            position: absolute;
            top: 0;
            transform: rotate(20.5deg);
            transform-origin: 50% 50%;
          }
        }
      }

      #{$selector}-link {
        @include fs-min-width($layout-header-bp) {
          max-width: fs-rem(140px);
        }
      }
    }// end -primary

    &--secondary {
      @include fs-max-width($layout-header-bp - 1px) {
        float: left;
        height: fs-rem($divider-height);
        line-height: fs-rem($divider-height);
        margin-top: fs-rem($mobile-vert-margin);
      }

      // First secondary link
      #{$item-selector}--primary + & {
        @include fs-min-width($layout-header-bp) {
          margin-left: auto;// right align
        }
      }

      & + & {
        #{$selector}-link {
          @include fs-max-width($layout-header-bp - 1px) {
            border-left: 1px solid rgba(#fff, 0.22);
            margin-left: fs-rem(20px);
            padding-left: fs-rem(20px);
          }

          @include fs-min-width($layout-header-bp) {
            margin-left: fs-rem(25px);
          }
        }
      }
    }// end -secondary
  }// end item

  &-link {
    $link-selector: &;
    display: block;
    position: relative;// for icon

    @include fs-attention {
      #{$link-selector}-icon {
        transform: translate(0, -50%);
      }
    }

    &-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-2px, -50%);
      transition: transform 150ms ease-in-out;

      @include fs-min-width($layout-header-bp) {
        display: none;
      }
    }// end icon

    // Primary nav link styles
    &-title {
      @include fs-font-smoothing;
      display: block;
      font-size: fs-rem(20px);
      font-weight: bold;
      line-height: 1;
      margin-bottom: fs-rem(5px);

      @include fs-min-width($layout-header-bp) {
        font-size: fs-rem(16px);
      }
    }// end title

    &-description {
      display: inline-block;// required to set “text-decoration”
      font-size: fs-rem(16px);
      font-style: italic;
      line-height: 1.2;
      opacity: 0.8;
      text-decoration: none;

      @include fs-media((min-width: 320px, max-width: $layout-header-bp - 1px)) {
        width: 60%;
      }

      @include fs-min-width($layout-header-bp) {
        font-size: fs-rem(14px);
      }
    }// end description

    // Secondary nav link styles
    &-text {
      @include fs-font-smoothing;
      font-size: fs-rem(16px);
      font-weight: bold;
      vertical-align: middle;
      white-space: nowrap;
    }// end text

    .icon {
      margin-right: fs-rem(15px);
      text-decoration: none;
      vertical-align: middle;
    }

  }// end link

}// end Nav
