// CSS text stroke
// https://css-tricks.com/text-stroke-stuck-middle/
@mixin text-stroke($width, $color) {
  @supports
    (-webkit-text-stroke-color: #000) or (-moz-text-stroke-color: #000) {
      -moz-text-stroke-color: $color;
      -moz-text-stroke-width: $width;
      -webkit-text-stroke-color: $color;
      -webkit-text-stroke-width: $width;
  }
}
