//------------------------------------------------------------------------
// Background color classes
//
// sass-lint:disable no-important
// scss-lint:disable ImportantRule
//------------------------------------------------------------------------
.bg {

  // Default background color
  &-default {
    background-color: $bg-default;
  }

  // Fill parent with <img>
  // Polyfilled by background-picture.js
  &-image {
    display: block;
    position: relative;

    &-source {
      @include fs-absolute-fill;
      object-fit: cover;
    }
  }// end image

  // NOTE: The code below will dynamically generate classes based on
  //       the colors in the $fs-colors map in _color-vars.scss

  // Generate classes for each “base” color in $fs-colors
  @each $name, $val in map-get($fs-colors, 'base') {
    &-#{$name} {
      background-color: $val !important;
    }
  }

  // Generate classes for theme color overlay background
  @each $name, $val in map-get($fs-colors, 'overlay') {
    &-#{$name} {
      &--before {
        &:before {
          background-color: fs-color('overlay', $name) !important;
        }
      }
    }
  }

}// end bg
