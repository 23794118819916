//------------------------------------------------------------------------
// Homepage hero
//------------------------------------------------------------------------
.HomeHero {
  $medium-bp: 600px;
  $two-col-bp: $layout-homepage-hero-row;
  $fullsize-bp: 1580px;
  $horz-padding: 30px;
  $carousel-dot-size: 7px;
  $carousel-margin-above-dots: 30px;
  $carousel-margin-below-dots: 23px;
  color: #fff;
  display: flex;
  min-height: 100%;
  width: 100%;

  @include fs-min-width($medium-bp) {
    padding-bottom: fs-rem(60px);
    padding-top: fs-rem(40px);
  }

  @include fs-min-width($two-col-bp) {
    align-items: center;
    padding: 5% fs-rem(40px);
  }

  &--hide-mobile {
    @include fs-max-width($medium-bp - 1px) {
      display: none !important;
    }
  }// end -hide-mobile

  &--hide-desktop {
    @include fs-min-width($medium-bp) {
      display: none !important;
    }
  }// end -hide-desktop

  &-background {
    @include fs-absolute-fill;
    z-index: 1;

    img {
      @include fs-absolute-fill;
      object-fit: cover;
    }// end image
  }// end background

  &-video {
    @include fs-absolute-fill;
    object-fit: cover;
    z-index: 2;
  }// end videpo

  &-content {
    display: flex;
    position: relative;
    width: 100%;
    z-index: 2;

    @include fs-max-width($two-col-bp - 1px) {
      flex-direction: column;
    }

    @include fs-min-width($two-col-bp) {
      align-items: flex-end;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      max-width: fs-rem(1400px);
    }
  }// end content

  &-tagline {
    @include fs-max-width($medium-bp - 1px) {
      flex-grow: 1;
      position: relative;// for bg image/video positioning
    }

    @include fs-max-width($two-col-bp - 1px) {
      max-width: fs-rem(640px);
      padding: fs-rem(40px $horz-padding 50px);
    }

    @include fs-media((min-width: $medium-bp, max-width: $two-col-bp - 1px)) {
      margin-bottom: fs-rem(40px);
    }

    @include fs-min-width($two-col-bp) {
      flex: 0 1 50%;
    }

    @include fs-min-width(1366px) {
      flex-basis: 55%;
    }

    &-text {
      position: relative;
      // text-shadow: 0 0 17px rgba(#000, 0.35);
      z-index: 2;// above bg image/video

      &--small {
        @include fs-scale(font-size, (320px: 18px, $two-col-bp - 1px: 30px));
        @include fs-scale(font-size, ($two-col-bp: 21px, $fullsize-bp: 30px), $initial: false);
        display: block;
        font-weight: $montserrat-bold;
        letter-spacing: 1px;
        line-height: 1.2;
        margin-bottom: fs-em(20px, 32px);
      }// end -small

      &--large {
        @include fs-scale(font-size, (320px: 36px, $two-col-bp - 1px: 70px));
        @include fs-scale(font-size, ($two-col-bp: 52px, $fullsize-bp: 72px), $initial: false);
        display: block;
        font-weight: $klinic-bold;
        line-height: 1;
      }// end -large
    }// end text
  }// end tagline

  &-callout {
    background-color: fs-color('green');
    color: #fff;
    overflow: hidden;// hide other carousel slides
    width: 100%;

    @include fs-max-width($medium-bp - 1px) {
      margin-top: auto;// bottom align
    }

    @include fs-min-width($medium-bp) {
      margin-left: fs-rem($horz-padding);
      max-width: fs-rem(480px);
    }

    @include fs-min-width($two-col-bp) {
      width: 50%;
    }

    // Add bottom padding to carousel items to provide space for dots
    [aria-selected] {
      padding-bottom: fs-rem($carousel-margin-above-dots + $carousel-dot-size + $carousel-margin-below-dots);
    }

    // Wait until Flickity is ready and has an inline height (equal to tallest slide)
    // before setting the slide height to 100% so shorter slides fill the space.
    &.is-ready {
      [aria-selected] {
        height: 100%;
      }
    }

    // Flickity overrides
    .flickity {
      &-page-dots {
        bottom: 23px;

        .dot {
          $gutter: 14px;
          background-color: rgba(#fff, 0.75);
          margin-left: fs-rem($gutter * 0.5);
          margin-right: fs-rem($gutter * 0.5);

          &.is-selected {
            background-color: #fff;
          }
        }// end dot
      }// end page-dots

    }// flickity

  }// end callout
}// end HomeHero
