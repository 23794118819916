//------------------------------------------------------------------------
// Main content wrapper
//------------------------------------------------------------------------
.Main {
  .js & {
    height: calc(100vh - #{fs-rem($layout-header-height-mobile)});
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;// iOS “momentum” scrolling

    @include fs-min-width($layout-header-bp) {
      height: calc(100vh - #{fs-rem($layout-header-height-desktop)});
    }
  }

  .homepage & {
    @include fs-media((min-width: $layout-homepage-hero-row, min-aspect-ratio: "33.33%1")) {
      overflow: hidden;// hide scrollbars in Windows
    }
  }

  .l-page &,
  .l-map & {
    @include fs-min-width($layout-article-cols) {
      overflow: hidden;// hide scrollbars in Windows
    }
  }

}// end Main