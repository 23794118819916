//------------------------------------------------------------------------
// Expandable transition styles
//------------------------------------------------------------------------
@mixin expandable($speed: 500ms, $easing: fs-easing('easeOutCubic')) {
  // Expanded
  &,
  .js &[aria-hidden="false"] {
    overflow: hidden;
    transition: max-height $speed $easing,
                visibility 0s linear 0s;
    visibility: visible;
  }

  // Collapsed
  &[aria-hidden="true"],
  // Selector below prevents a flash of unstyled content (FOUC)
  .js &:not([aria-hidden]) {
    max-height: 0 !important;// !important required to override inline styles added by JS
    transition: max-height $speed $easing,
                visibility 0s linear $speed;
    visibility: hidden;
  }
}

@mixin slideToggle($speed: 500ms, $easing: fs-easing('easeOutCubic')) {
  // Expanded
  &,
  .js &[aria-hidden="false"] {
    overflow: hidden;
    transform: translateY(0);
    transition: transform $speed $easing,
                visibility 0s linear 0s;
    visibility: visible;
  }

  // Collapsed
  &[aria-hidden="true"],
  // Selector below prevents a flash of unstyled content (FOUC)
  .js &:not([aria-hidden]) {
    transform: translateY(-100%);
    transition: transform $speed $easing,
                visibility 0s linear $speed;
    visibility: hidden;
  }
}
