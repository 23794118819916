//------------------------------------------------------------------------
// Layout helper classes
//------------------------------------------------------------------------
.l {
  &-padding {
    @include fs-scale(padding-left padding-right, (320px: 10px, 1260px: 50px));

    @media print {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }// end padding

  &-wrap {
    @include fs-clearfix;
    margin-left: auto;
    margin-right: auto;
    max-width: $layout-max-width;

    @media print {
      max-width: none !important;
    }
  }// end wrap

  &-wrap--narrow {
    @include fs-clearfix;
    margin-left: auto;
    margin-right: auto;
    max-width: fs-rem($layout-max-width-narrow);

    @media print {
      max-width: none !important;
    }
  }// end wrap--narrow

  // For full-width strata that have a max-width (e.g. navigation wrapper)
  &-wrap--wide {
    @include fs-clearfix;
    margin-left: auto;
    margin-right: auto;
    max-width: fs-rem($layout-max-width-wide);

    @media print {
      max-width: none !important;
    }
  }// end wrap--wide

}// end l
