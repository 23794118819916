//------------------------------------------------------------------------
// Map
//------------------------------------------------------------------------
.Map {
  $speed: 500ms;
  $easing: fs-easing('easeOutCubic');
  $sidebar-bp: 768px;
  $sidebar-max-width: 460px;
  $gray: rgba(#fff, 0.7);
  $lightGray: rgba(#fff, 0.54);
  $close-button-mobile-offset: 10px;
  height: 100%;
  overflow: hidden;// prevent horizontal scrolling due to sidebar
  position: relative;
  width: 100%;

  &-warning {
    align-items: center;
    background-color: fs-color('blue');
    color: $c-default;
    display: flex;
    height: 100%;
    justify-content: flex-end;

    &-content {
      color: #fff;
      font-size: fs-rem(22px);
      padding-bottom: fs-rem(30px);
      padding-top: fs-rem(30px);
      text-align: center;

      @include fs-min-width($layout-header-bp) {
        font-size: fs-rem(40px);
        width: calc(100% - #{fs-rem($sidebar-max-width)});
      }
    }// end content

    a {
      color: inherit;
      font-weight: bold;
      text-decoration: underline;
    }
  }// end warning

  // Info toggle button
  &-toggle {
    $size: 60px;
    @include fs-scale(left top, (320px: 20px, $layout-header-bp: 50px));
    background-color: #000;
    border-radius: 50%;
    color: #fff;
    font-size: fs-rem(30px);
    line-height: $size;
    height: fs-rem($size);
    position: absolute;
    text-align: center;
    width: fs-rem($size);
    z-index: 1;

    @include button-attention {
      background-color: fs-color('orange');
    }
  }// end toggle

  &-info {
    @include fs-scale(padding-top padding-bottom, (320px: 20px, 900px: 40px));
    background-color: #000;
    color: #fff;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;// above toggle button

    .no-js & {
      position: static;
    }

    // Full viewport on smaller phones to avoid thin strip of map showing at the bottom
    @include fs-media((max-height: 600px)) {
      height: calc(100vh - #{fs-rem($layout-header-height-mobile)});
    }

    @include fs-max-width($layout-header-bp - 1px) {
      top: fs-rem($layout-header-height-mobile);
    }

    @include fs-min-width($layout-header-bp) {
      align-items: center;
      bottom: 0;
      display: flex;
      left: 0;
      max-width: 50vw;
      padding-left: fs-rem(50px);
      padding-right: fs-rem(50px);
      top: 0;
      width: fs-rem(450px);
    }

    // Expanded
    &,
    .js &[aria-hidden="false"] {
      overflow-y: scroll;
      transform: translateX(0);
      transition: transform $speed $easing,
                  visibility 0s linear 0s;
      visibility: visible;
      -webkit-overflow-scrolling: touch;// iOS “momentum” scrolling
    }

    // Collapsed
    &[aria-hidden="true"],
    // Selector below prevents a flash of unstyled content (FOUC)
    .js &:not([aria-hidden]) {
      transform: translateX(-100%);
      transition: transform $speed $easing,
                  visibility 0s linear $speed;
      visibility: hidden;
    }

    &-wrap {
      position: relative;
    }// end wrap

    &-heading {
      font-size: fs-rem(20px);
      line-height: 1.25;
      margin-bottom: fs-rem(20px);

      @include fs-max-width($layout-header-bp - 1px) {
        padding-right: fs-rem(60px);
      }
    }// end heading

    &-content {
      font-size: fs-rem(14px);
      margin-bottom: fs-rem(30px);
    }// end content

    &-links {
      $row-bp: 320px;
      $gutter: 10px;
      @include fs-scale(font-size, (320px: 14px, 400px: 16px));

      @include fs-min-width($row-bp) {
        display: flex;
        margin-left: fs-rem(-$gutter);
      }

      &-cta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: fs-rem(20px);

        @include fs-min-width($row-bp) {
          flex-grow: 1;
          margin-left: fs-rem($gutter);
        }

        &-meta {
          @include fs-font-smoothing;
          display: block;
          font-size: fs-rem(12px);
          margin-top: fs-rem(5px);
          opacity: 0.75;
        }// end meta
      }// end cta
    }// end link

    &-close {
      position: absolute;
      right: fs-rem($close-button-mobile-offset);
      top: fs-rem($close-button-mobile-offset);

      @include fs-min-width($layout-header-bp) {
        right: fs-rem(30px);
        top: fs-rem(30px);
      }
    }// end close

    &-key {
      $outer-circle-width: 55px;
      $inner-circle-width: 12px;
      align-items: center;
      display: flex;
      margin-top: fs-rem(20px);

      @include fs-min-width($sidebar-bp) {
        margin-top: 5vh;
      }

      &-circle {
        $bg-color: fs-color('orange');
        background-color: rgba($bg-color, 0.4);
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: fs-rem(20px);
        position: relative;
        max-width: fs-rem($outer-circle-width);
        width: 15%;

        &:before {
          content: '';
          display: block;
          padding-top: 100%;
        }

        &:after {
          background-color: $bg-color;
          border-radius: 50%;
          content: '';
          flex-grow: 1;
          height: fs-rem($inner-circle-width);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: fs-rem($inner-circle-width);
        }
      }// end circle

      &-content {
        @include fs-font-smoothing;
      }// end content

      &-heading {
        @include fs-scale(font-size, (320px: 14px, $sidebar-max-width: 16px));
        font-weight: $montserrat-bold;
        line-height: 1.25;
      }// end heading

      &-text {
        @include fs-scale(font-size, (320px: 13px, $sidebar-max-width: 14px));
        line-height: 1.35;
      }// end description
    }// end key
  }// end info

  &-sidebar {
    $close-button-desktop-offset: 20px;
    background-color: rgba(#000, 0.82);
    bottom: 0;
    color: #fff;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;// iOS “momentum” scrolling
    padding-bottom: fs-rem(40px);
    padding-right: 0;// cancel out l-padding
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;// Above map UI controls

    @include fs-min-width($sidebar-bp) {
      max-width: fs-rem($sidebar-max-width);
    }

    @include fs-min-width($layout-header-bp) {
      padding-bottom: fs-rem($layout-header-height-desktop);
    }

    // Expanded
    &,
    .js &[aria-hidden="false"] {
      transform: translateX(0);
      transition: transform $speed $easing,
                  visibility 0s linear 0s;
      visibility: visible;
    }

    // Collapsed
    &[aria-hidden="true"],
    // Selector below prevents a flash of unstyled content (FOUC)
    .js &:not([aria-hidden]) {
      transform: translateX(-100%);
      transition: transform $speed $easing,
                  visibility 0s linear $speed;
      visibility: hidden;
    }

    &-close {
      position: absolute;
      right: fs-rem($close-button-mobile-offset);
      top: fs-rem($close-button-mobile-offset);

      @supports (position: sticky) {
        display: block;
        margin-left: auto;
        position: sticky;
      }

      @include fs-min-width($layout-header-bp) {
        right: fs-rem($close-button-desktop-offset);
        top: fs-rem($close-button-desktop-offset);
      }
    }// end close

    &-content {
      padding-right: fs-rem($layout-close-button-size);

      @include fs-min-width($layout-header-bp) {
        padding-right: fs-rem($layout-close-button-size + $close-button-desktop-offset);
        padding-top: fs-rem($close-button-desktop-offset);
      }
    }// end content

    &-parkLink {
      color: $gray;
      font-size: fs-rem(14px);
      font-weight: $montserrat-bold;
      text-transform: uppercase;

      @include fs-attention {
        .icon {
          transform: translateX(2px);
        }
      }

      .icon {
        stroke: currentColor;
        display: inline-block;
        vertical-align: text-bottom;
        transition: transform 150ms ease-in-out;
      }
    }// end parkLink

    &-parkName {
      font-size: fs-rem(33px);
      line-height: 1.1;
      margin-bottom: fs-rem(10px);

      a {
        color: inherit;
      }
    }// end parkName

    &-species {
      font-size: fs-rem(14px);
      line-height: 1;
      margin-bottom: fs-rem(24px);
      text-transform: uppercase;

      &-count {
        font-weight: $montserrat-bold;
      }// end count
    }// end species

    &-list {
    }// end list

    &-item {
      @include fs-scale(margin-bottom padding-bottom, (320px: 8px, 600px: 12px));
      $vert-margin: 8px;
      border-bottom: 1px solid $lightGray;
      color: $gray;
      // margin-bottom: fs-rem($vert-margin);
      // padding-bottom: fs-rem($vert-margin);
    }// end item

    &-meta {
      margin-bottom: fs-rem(4px);
    }// end meta

    &-status {
      font-size: fs-rem(14px);
      font-weight: $montserrat-medium;
      margin-right: fs-rem(10px);
    }// end status

    &-group {
      border: 1px solid $lightGray;
      border-radius: 200px;// arbitrary large number
      display: inline-block;
      font-size: fs-rem(12px);
      line-height: 1;
      padding: fs-rem(4px 8px);
    }// end group

    &-name {
      @include fs-font-smoothing;
      color: #fff;
      font-size: fs-rem(16px);
      font-weight: $montserrat-bold;
      line-height: 1.25;
    }// end name

    &-link {
      color: inherit;

      @include fs-attention {
        text-decoration: underline;
      }
    }// end parkName

    &-scientific {
      font-size: fs-rem(14px);
      font-style: italic;
    }// end scientific

  }// end sidebar

  &-tooltip {
    $bg-color: fs-color('offwhite');
    // background-color: fs-color('offwhite');
    padding: fs-rem(6px);
    text-align: center;

    &-name {
      font-size: fs-rem(15px);
      font-weight: bold;
    }// end name

    &-link {
      @include link-black;
    }// end link

    &-number {
      font-size: fs-rem(14px);
    }// end nuber

    @at-root .mapboxgl-popup {
      $tooltip-selector: &;

      &-content {
        background-color: $bg-color !important;
      }

      &-anchor-bottom,
      &-anchor-bottom-left,
      &-anchor-bottom-right {
        #{$tooltip-selector}-tip {
          border-top-color: $bg-color !important;
        }
      }

      &-anchor-top,
      &-anchor-top-left,
      &-anchor-top-right {
        #{$tooltip-selector}-tip {
          border-bottom-color: $bg-color !important;
        }
      }

      &-anchor-right {
        #{$tooltip-selector}-tip {
          border-left-color: $bg-color !important;
        }
      }

      &-anchor-left {
        #{$tooltip-selector}-tip {
          border-right-color: $bg-color !important;
        }
      }

      &-close-button {
        display: none;
      }
    }

    @at-root .mapboxgl-ctrl-attrib-button {
      display: none;
    }
  }// end tooltip
}// end Map
