//------------------------------------------------------------------------
// Form vars
//
// Used by _input-mixins.scss
//------------------------------------------------------------------------
$input-border-color: #e0e0e2;
$input-border-color-focus: fs-color('lightGreen');
$input-padding-horz: 16px;
$input-padding: fs-rem(12px $input-padding-horz 10px);
$input-disabled-background: #f4f4f4;
$input-disabled-border: #ccc;
$input-disabled-color: #707070;
$input-error-color: #b50909;
