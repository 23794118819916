// -------------------------------------------------------------------------
// Buttons
//
// scss-lint:disable ImportantRule, ColorVariable
// sass-lint:disable no-important, no-color-literals
// -------------------------------------------------------------------------
.btn {
  @include button-base;

  &-solid {
    @include button-solid;
  }

  &-lightGreen {
    @include button-lightGreen;
  }

  &-green {
    @include button-green;
  }

  &-darkGreen {
    @include button-darkGreen;
  }

  &-reset {
    @include button-reset;
  }

  &-close {
    @include button-close;
  }
}// end btn
