//------------------------------------------------------------------------
// Share links
//------------------------------------------------------------------------
.Share {
  &-item {
    &:not(:last-child) {
      margin-bottom: fs-rem(10px);
    }
  }// end item

  &-link {
    background-color: currentColor;
    border-radius: 50%;
    color: #fff;
    display: block;
    height: fs-rem($layout-share-link-size);
    opacity: 0.7;
    position: relative;
    text-align: center;
    width: fs-rem($layout-share-link-size);

    @include fs-attention {
      opacity: 1;
    }

    &-icon {
      $icon-size: 16px;
      fill: #fff;
      height: fs-rem($icon-size);
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: fs-rem($icon-size);

      // Set fill color to match background color
      @each $name, $val in map-get($fs-colors, 'base') {
        .bg-#{$name} & {
          fill: $val;
        }
      }
    }// end icon

  }// end link

}// end Share
