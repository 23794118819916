//------------------------------------------------------------------------
// Species navigation
//------------------------------------------------------------------------
.SpeciesNav {
  $selector: &;
  $col-2-bp: 460px;
  $col-3-bp: 1030px;
  $col-4-bp: 1280px;
  $gutter: 30px;
  $secondary-color: rgba(#fff, 0.65);
  color: #fff;

  @include fs-min-width($col-2-bp) {
    display: flex;
    flex-wrap: wrap;
    margin-left: fs-rem(-$gutter);
  }

  &-item {
    @include fs-font-smoothing;
    margin-bottom: fs-rem(26px);
    position: relative;

    // @include fs-media((min-width: $col-2-bp, max-width: $grid-bp - 1px)) {
    @include fs-min-width($col-2-bp) {
      padding-left: fs-rem($gutter);
      width: 50%;
    }

    // @include fs-media((min-width: $col-3-bp, max-width: $grid-bp - 1px)) {
    @include fs-min-width($col-3-bp) {
      width: 33.33%;
    }

    @include fs-min-width($col-4-bp) {
      margin-bottom: 5vh;
      width: 25%;
    }
  }// end item

  &-icon {
    fill: currentColor;
    float: left;
    margin-right: fs-rem(20px);
    margin-top: fs-rem(18px);
    max-height: fs-rem(20px);
  }// end icon

  &-content {
    overflow: hidden;
  }// end content

  &-status {
    color: $secondary-color;
    font-size: fs-rem(10px);
    font-weight: $montserrat-bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }// end status

  &-name {
    color: #fff;
    font-size: fs-rem(14px);
    font-weight: $montserrat-bold;

    &:after {
      @include fs-absolute-fill;
      content: '';
    }
  }// end name

  &-scientific {
    color: $secondary-color;
    font-style: italic;
    font-size: fs-rem(14px);
    font-weight: $klinic-bold;
  }// end scientific

}// end SpeciesNav
