//------------------------------------------------------------------------
// Success story nav
//------------------------------------------------------------------------
.StoryNav {
  $icon-width: 10px;
  $icon-gutter: 70px;

  &-heading {
    font-size: fs-rem(21px);
    font-weight: $montserrat-bold;
    line-height: 1.2;
    margin-bottom: fs-rem(30px);
  }// end heading

  &-item {
    font-size: fs-rem(24px);
    margin-bottom: fs-rem(30px);
  }// end item

  &-link {
    display: block;
    position: relative;
    padding-right: calc(10% + #{fs-rem($icon-width)});

    @include fs-all-states {
      color: #fff;
    }

    .icon {
      fill: currentColor;
      opacity: 0.8;
      position: absolute;
      right: 0;
      top: 50%;
      transition: all 150ms ease-in-out;
      transform: translate(-2px, -50%);
      vertical-align: middle;
    }

    @include fs-attention {
      .icon {
        opacity: 1;
        transform: translate(0, -50%);
      }
    }
  }// end link

}// end StoryNav
