//------------------------------------------------------------------------
// Mixins to apply webfonts only when they have loaded to prevent a FOIT
// Depends on font-face.js and Sass vars in _font-vars.scss
// Used to create helper classes in _font-helpers.scss
// Note: This will no longer be necessary once `font-display` is supported
// http://caniuse.com/#feat=css-font-rendering-controls
//------------------------------------------------------------------------
@mixin font-klinic($important: false) {
  font-family: $klinic, $klinic-fallback #{if($important, '!important', '')};
}

@mixin font-montserrat($important: false) {
  font-family: $montserrat, $montserrat-fallback #{if($important, '!important', '')};
}
