//------------------------------------------------------------------------
// Species page layout and component styling
//------------------------------------------------------------------------
.Species {
  $row-bp: 960px;
  $full-width-bp: 1200px;
  $primary-col-width: 56.25%;
  $share-top-bp: 1024px;

  @include fs-min-width($row-bp) {
    display: flex;
    height: 100%;
  }

  &-primary {
    overflow: hidden;// prevent park icon from causing horizontal scrolling in mobile
    padding-top: fs-rem(30px);
    position: relative;

    @include fs-min-width($row-bp) {
      flex: 1 0 $primary-col-width;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;// iOS “momentum” scrolling
    }
  }// end primary

  &-secondary {
    position: relative;

    @include fs-min-width($row-bp) {
      flex: 1 1 #{100% - $primary-col-width};
    }
  }// end secondary

  &-parkIcon {
    max-width: 80%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(15%, -15%);
    width: fs-rem(450px);
    z-index: 1;

    // Maintain 1:1 aspect ratio
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    @include fs-min-width($full-width-bp) {
      left: 0;
      position: fixed;
      right: auto;
      transform: translate(-20%, -15%);
    }

    &-icon {
      @include fs-absolute-fill;
      opacity: 0.13;

      svg {
        stroke: fs-color('black');
        width: fs-rem(450px);

        pattern {
          stroke-width: 0;
        }
      }
    }// end icon

    &-link {
      $size: 100px;
      display: block;
      height: fs-rem($size);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: fs-rem($size);

      @include fs-max-width($full-width-bp - 1px) {
        display: none;
      }

      &-icon {
        opacity: 0.5;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: rotate(45deg) translate(-50%, -50%);
        transform-origin: 0 0;
        transition: all 150ms ease-in-out;
      }// end icon

      @include fs-attention {
        svg {
          opacity: 1;
        }
      }
    }// end link
  }// end parkIcon

  &-content {
    padding-bottom: fs-rem(100px);
    position: relative;
    z-index: 2;// above park icon

    @include fs-max-width($row-bp - 1px) {
      margin-left: auto;
      margin-right: auto;
      max-width: fs-rem(650px);
    }

    // 1. Add extra padding for when CTA overlaps the left column
    // 2. Add padding since the thumbnail is hidden
    @include fs-min-width($row-bp) {
      padding-right: fs-rem(10px);// [1]
      padding-top: 10%;// [2]
    }

    @include fs-min-width($full-width-bp) {
      margin-left: auto;
      width: 75%;
    }
  }// end content

  &-thumb {
    border: 12px solid #fff;
    border-radius: 50%;
    display: block;
    overflow: hidden;
    margin-bottom: fs-rem(20px);
    width: fs-rem(150px);

    @include fs-min-width($row-bp) {
      display: none;
    }

    &-image {
      display: block;
      width: 100%;
    }// end image
  }// end thumb

  &-titleBlock {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: fs-rem(30px);
  }// end titleBlock

  &-name {
    order: 3;
  }// end name

  &-scientific {
    font-style: italic;
    font-size: fs-rem(16px);
    margin-top: fs-rem(16px);
    order: 2;
  }// end scientific

  &-year {
    font-style: italic;
    margin-top: fs-rem(10px);
    order: 4;
  }// end year

  &-park {
    @include link-black;
    @include fs-font-smoothing;
    @include fs-scale(font-size, (320px: 13px, 540px: 16px));
    order: 1;
    font-weight: $montserrat-medium;
    text-transform: uppercase;

    .icon {
      fill: none;
      stroke: currentColor;
      stroke-width: 2px;
    }
  }// end park

  &-share {
    margin-top: fs-rem(20px);
    order: 5;
  }// end share

  .Share {
    order: 4;
    position: relative;
    z-index: 5;// above content

    @include fs-min-width($share-top-bp) {
      position: absolute;
      right: fs-rem(0);
      top: fs-rem(30px);
    }

    &-list {
      @include fs-max-width($share-top-bp - 1px) {
        display: flex;
      }
    }// end list

    &-item {
      @include fs-max-width($share-top-bp - 1px) {
        margin-right: fs-rem(15px);
      }
    }// end item

    &-link {
      color: $c-default;
    }// end link
  }// end share

  &-description {
    margin-bottom: fs-rem(30px);
  }// end description

  &-body {
    &-heading {
      margin-bottom: fs-rem(12px);
    }// end heading

    &-description {
      margin-bottom: fs-rem(40px);

      &:last-child {
        margin-bottom: 0;
      }
    }// end description
  }// end body

  &-secondary {
    position: relative;
  }// end secondary

  &-background {
    overflow: hidden;
    z-index: 1;

    @include fs-max-width($row-bp - 1px) {
      display: none;
    }

    @include fs-min-width($row-bp) {
      @include fs-absolute-fill;

      img {
        @include fs-absolute-fill;
        object-fit: cover;
      }
    }
  }// end background

  &-cta {
    @include fs-min-width($row-bp) {
      bottom: fs-rem(50px);
      left: fs-rem(-40px);
      max-width: 100%;
      position: absolute;
      z-index: 2;

      .ua-ie11win7 & {
        left: 0;
      }
    }
  }// end cta

}// end Species
