//------------------------------------------------------------------------
// Web fonts
// - Add preload links in _head.html.erb for all woff2 files
// - Add FontFaceObserver tests for each font file in font-face.js
//
// Note: Only woff and woff2 are required
// https://css-tricks.com/snippets/css/using-font-face/#article-header-id-1
//------------------------------------------------------------------------
// NOTE: If using Typekit, save fonts locally by using links in CSS file,
//       e.g. https://use.typekit.net/PROJECT_ID.css, and copy legal info here.
//       This technically officially supported, but should be fine as long
//       as we’re linking to Typekit’s tracker (see application.html.erb).


// Montserrat Regular
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'),
       url('../fonts/montserrat/montserrat-v12-latin-regular.woff2') format('woff2'),
       url('../fonts/montserrat/montserrat-v12-latin-regular.woff') format('woff');
}

// Montserrat Medium
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat/montserrat-v12-latin-500.woff2') format('woff2'),
       url('../fonts/montserrat/montserrat-v12-latin-500.woff') format('woff');
}

// Montserrat Bold
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat/montserrat-v12-latin-700.woff2') format('woff2'),
       url('../fonts/montserrat/montserrat-v12-latin-700.woff') format('woff');
}

// Klinic Book
@font-face {
  font-display: swap;
  font-family: 'Klinic';
  font-style: normal;
  font-weight: 400;
  src: //local('Klinic Slab Book'), local('KlinicSlab-Book'),
       url('../fonts/klinic/klinicslabbook-webfont.woff2') format('woff2'),
       url('../fonts/klinic/klinicslabbook-webfont.woff') format('woff');
}

// Klinic Book Italic
@font-face {
  font-display: swap;
  font-family: 'Klinic';
  font-style: italic;
  font-weight: 400;
  src: //local('Klinic Slab Book Italic'), local('KlinicSlab-BookItalic'),
       url('../fonts/klinic/klinicslabbookit-webfont.woff2') format('woff2'),
       url('../fonts/klinic/klinicslabbookit-webfont.woff') format('woff');
}

// Klinic Light
@font-face {
  font-display: swap;
  font-family: 'Klinic';
  font-style: normal;
  font-weight: 300;
  src: //local('Klinic Slab Light'), local('KlinicSlab-Light'),
       url('../fonts/klinic/klinicslablight-webfont.woff2') format('woff2'),
       url('../fonts/klinic/klinicslablight-webfont.woff') format('woff');
}

// Klinic Light Italic
@font-face {
  font-display: swap;
  font-family: 'Klinic';
  font-style: italic;
  font-weight: 300;
  src: //local('Klinic Slab Light Italic'), local('KlinicSlab-LightItalic'),
       url('../fonts/klinic/klinicslablightit-webfont.woff2') format('woff2'),
       url('../fonts/klinic/klinicslablightit-webfont.woff') format('woff');
}

// Klinic Bold
@font-face {
  font-display: swap;
  font-family: 'Klinic';
  font-style: normal;
  font-weight: 700;
  src: //local('Klinic Slab Bold'), local('KlinicSlab-Bold'),
       url('../fonts/klinic/klinicslabbold-webfont.woff2') format('woff2'),
       url('../fonts/klinic/klinicslabbold-webfont.woff') format('woff');
}

// Klinic Bold Italic
@font-face {
  font-display: swap;
  font-family: 'Klinic';
  font-style: italic;
  font-weight: 700;
  src: //local('Klinic Slab Bold Italic'), local('KlinicSlab-BoldItalic'),
       url('../fonts/klinic/klinicslabboldit-webfont.woff2') format('woff2'),
       url('../fonts/klinic/klinicslabboldit-webfont.woff') format('woff');
}
