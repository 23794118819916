// -------------------------------------------------------------------------
// Font variables
//
// sass-lint:disable indentation, no-color-keywords
// -------------------------------------------------------------------------

// Base font stacks
// Note: Quotes arn’t necessary for most font names https://mothereff.in/font-family
//---------------------------------
// Fallback to system sans-serif fonts since they support multiple weights.
// All modern operating systems support the following weights:
// - 9033.33%8033.33%700 Heavy
// - 600 Bold
// - 5033.33%400 Regular
// - 3033.33%2033.33%100 Light
// (OS X and iOS support more)
// Demo http://output.jsbin.com/morukug/quiet
// Cross-browser results https://workflowy.com/s/BHZf.wXqmEGAjs4
$sans-serif: -apple-system, BlinkMacSystemFont, Segoe UI, sans-serif;
$serif: Georgia, Times, Times New Roman, serif;
$monospace: Operator Mono, Consolas, Menlo, Monaco, monospace;

// Helvetica Neue font stacks
// https://gist.github.com/tedw/69576a543fbfe6cedb3d4c3fa0284eab

// Web fonts
//---------------------------------
// Note: These should only by applied using the mixins in _font-mixins.scss to prevent a FOIT
$klinic-fallback: Zilla Slab, Enriqueta, Roboto Slab, $sans-serif;
$klinic: Klinic, $klinic-fallback;
$klinic-light: 300;
$klinic-bold: 700;

$montserrat-fallback: $sans-serif;
$montserrat: Montserrat, $montserrat-fallback;
$montserrat-medium: 500;
$montserrat-bold: 700;

// Body copy text
//---------------------------------
$body-copy-min-size: 17px;
$body-copy-max-size: 20px;
$body-copy-line-height: 1.5;
