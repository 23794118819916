// -------------------------------------------------------------------------
// Button Styles
// -------------------------------------------------------------------------
@mixin button-reset {
  background-color: transparent;
  background-image: none;// for Firefox mobile
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  text-align: center;
  transition: background-color 150ms ease-in-out,
              border 150ms ease-in-out,
              color 150ms ease-in-out,
              transform 50ms ease-in-out;
}

// Default button style (border)
//---------------------------------
@mixin button-base {
  @include button-reset;
  font-size: fs-rem(16px);

  &:active {
    transform: scale(0.97);
  }

  // Disabled Button Styles
  &:disabled {
    background-color: #eee !important;
    border-color: #bbb !important;
    color: #666 !important;
    cursor: not-allowed !important;
  }
}// end button-base

@mixin button-solid {
  @include font-montserrat;
  color: #fff;
  padding: fs-rem(16px 20px);
}

@mixin button-lightGreen {
  background-color: fs-color('lightGreen');

  @include button-attention {
    background-color: darken(fs-color('lightGreen'), 5%);
  }
}

@mixin button-green {
  $green: #01714a;
  background-color: $green;

  @include button-attention {
    background-color: darken($green, 5%);
  }
}

@mixin button-darkGreen {
  $darkGreen: #384534;
  background-color: $darkGreen;

  @include button-attention {
    background-color: lighten($darkGreen, 5%);
  }
}

@mixin button-close {
  border: 1px solid currentColor;
  border-radius: 50%;
  color: #fff;
  display: block;
  height: fs-rem($layout-close-button-size);
  width: fs-rem($layout-close-button-size);

  @include button-attention {
    background-color: #fff;
    color: #000;
  }

  svg {
    stroke: currentColor;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
