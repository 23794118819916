//------------------------------------------------------------------------
// Parks navigation
//------------------------------------------------------------------------
.ParksNav {
  $selector: &;
  $col-2-bp: 500px;
  $col-3-bp: 900px;
  $col-4-bp: 1200px;
  $text-scale-max-bp: 2000px;
  // $grid-bp: 1300px;
  $gutter: 20px;
  // $min-gutter: 30px;
  // $max-gutter: 40px;

  // @include fs-media((min-width: $col-2-bp, max-width: $grid-bp - 1px)) {
  @include fs-min-width($col-2-bp) {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: fs-rem(-$gutter);
  }

  // @supports (display: grid) {
  //   @include fs-min-width($grid-bp) {
  //     display: grid;
  //     grid-column-gap: 30px;
  //     grid-row-gap: 30px;
  //     // grid-template-columns: repeat( auto-fit, minmax(130px, 1fr) );
  //     grid-template-columns: repeat(7, 1fr);
  //   }
  // }

  &-item {
    margin-bottom: fs-rem(15px);

    // @include fs-media((min-width: $col-2-bp, max-width: $grid-bp - 1px)) {
    @include fs-min-width($col-2-bp) {
      padding-left: fs-rem($gutter);
      width: 50%;
    }

    // @include fs-media((min-width: $col-3-bp, max-width: $grid-bp - 1px)) {
    @include fs-min-width($col-3-bp) {
      width: 33.33%;
    }

    @include fs-min-width($col-4-bp) {
      margin-bottom: 5vh;
      width: 25%;
    }

    // @include fs-min-width($grid-bp) {
    //   text-align: center;
    // }
  }// end item

  &-link {
    align-items: center;
    color: #fff;
    display: flex;

    @include fs-attention {
      #{$selector}-icon {
        opacity: 0.8;
      }
    }
  }// end link

  &-icon {
    flex-shrink: 0;
    margin-right: fs-rem(15px);
    transition: all 150ms ease-in-out;
    width: 31%;

    @include fs-media((min-width: $col-3-bp, max-width: $col-4-bp - 1px)) {
      width: 28%;
    }

    // svg {
    //   @include fs-absolute-fill;
    //   fill: currentColor;
    //   stroke: currentColor;
    //   transition: all 150ms ease-in-out;
    // }

    // Hide shading on dark backgrounds
    // [fill^="url"] {
    //   display: none;
    // }
  }// end icon

  &-text {
    &-name {
      @include fs-scale(font-size, (320px: 15px, $col-2-bp - 1px: 21px));
      @include fs-scale(font-size, ($col-2-bp: 15px, $col-3-bp - 1px: 21px), $initial: false);
      @include fs-scale(font-size, ($col-3-bp: 15px, $col-4-bp - 1px: 18px), $initial: false);
      @include fs-scale(font-size, ($col-4-bp: 15px, $text-scale-max-bp: 30px), $initial: false);
      display: block;
      margin-bottom: fs-rem(6px);
    }// end name

    &-state {
      @include fs-scale(font-size, (320px: 13px, $col-2-bp - 1px: 16px));
      @include fs-scale(font-size, ($col-2-bp: 13px, $col-3-bp - 1px: 16px), $initial: false);
      @include fs-scale(font-size, ($col-3-bp: 13px, $col-4-bp - 1px: 16px), $initial: false);
      @include fs-scale(font-size, ($col-4-bp: 13px, $text-scale-max-bp: 16px), $initial: false);
      display: inline-block;// required to set “text-decoration”
      font-style: italic;
      opacity: 0.8;
      text-decoration: none;
    }// end state
  }// end text

}// end ParksNav
