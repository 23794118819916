//------------------------------------------------------------------------
// Two-col layout for ESA nav landing page
//------------------------------------------------------------------------
.LandingCols {
  $selector: &;
  $row-bp: 1080px;// for ESA nav landing page
  $lightGray: rgba(#d8d8d8, 0.27);
  $vert-padding-max: 50px;
  $vert-padding-min: 30px;
  color: #fff;

  @include fs-min-width($row-bp) {
    display: flex;
    justify-content: space-between;
    min-height: 100%;
  }

  &-primary {
    @include fs-scale(padding-bottom padding-top, (320px: $vert-padding-min, 900px: $vert-padding-max));

    @include fs-min-width($row-bp) {
      flex-shrink: 0;
      padding-right: 10%;
      width: 56%;
    }

    &-wrap {
      @include fs-min-width($row-bp) {
        position: sticky;
        top: 50%;
        transform: translateY(-50%);
      }
    }// end wrap
  }// end primary

  // 1. Vertically center content
  &-secondary {
    @include fs-scale(padding-bottom padding-top, (320px: $vert-padding-min, 900px: $vert-padding-max));

    @include fs-media((min-width: 680px, max-width: $row-bp - 1px)) {
      .StoryNav-list {
        display: grid;
        // grid-auto-flow: column;
        grid-column-gap: 30px;
        grid-template-columns: 1fr 1fr;
      }
    }

    @include fs-min-width($row-bp) {
      border-left: 1px solid $lightGray;
      display: flex;// [1]
      flex-direction: column;// [1]
      flex-grow: 1;
      justify-content: center;// [1]
      padding-left: 6.5%;
    }
  }// end secondary

}// end LandingCols
