//------------------------------------------------------------------------
// Park and species landing page wrappers
//
// Vertically center but allow scrolling
//------------------------------------------------------------------------
.Landing {
  $selector: &;
  $lightGray: rgba(#fff, 0.68);
  @include fs-scale(padding-bottom padding-top, (320px: 30px, 900px: 50px));
  align-content: center;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  min-height: 100%;

  &-heading {
    @include fs-scale(font-size, (320px: 28px, 1023px: 34px));
    @include fs-scale(font-size, (1024px: 28px, 1200px: 34px), $initial: false);
    @include fs-font-smoothing;
    font-weight: $montserrat-bold;
    margin-bottom: 1em;
    width: 100%;// force following content to next row
  }// end heading

  &-title {
    @include fs-scale(font-size, (320px: 39px, 1023px: 58px));
    @include fs-scale(font-size, (1024px: 50px, 1200px: 58px), $initial: false);
    font-weight: $montserrat-bold;
    line-height: 1.1;
    margin-bottom: fs-rem(30px);
  }// end title

  &-link {
    border-bottom: 2px solid transparent;

    @include fs-all-states {
      color: #fff;
    }

    .icon {
      transition: all 150ms ease-in-out;
      vertical-align: baseline;

      path {
        stroke: $lightGray;
      }
    }

    @include fs-attention {
      .icon {
        transform: translateX(2px);

        path {
          stroke: #fff;
        }
      }
    }
  }// end link

  &-description {
    @include fs-scale(font-size, (320px: 22px, 1200px: 24px));
    color: $lightGray;
    font-weight: $montserrat-medium;
    line-height: 1.3;
  }// end description
}// end Landing
