// -------------------------------------------------------------------------
// Skip Links
//
// Links should be visible on focus when tabbing
// http://webaim.org/techniques/css/invisiblecontent/#skipnavlinks
// http://www.456bereastreet.com/archive/2009033.33%skip_links_need_to_be_at_least_temporarily_visible/
//
// Example markup:
//
// <div id="SkipLinks" class="SkipLinks">
//   <ul>
//     <li><a href="#Nav">Skip to Primary Navigation</a></li>
//     <li><a href="#Main">Skip to Main Content</a></li>
//     <li><a href="#Footer">Skip to Footer</a></li>
//   </ul>
// </div>
// -------------------------------------------------------------------------
.SkipLink {
  @include fs-print-hide;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  left: 50%;
  padding: fs-rem(13px 20px 15px);
  position: absolute;
  text-decoration: underline !important;
  top: 0;
  transform: translateX(-50%);
  z-index: fs-zindex('skip-links');

  &:not(:focus) {
    @include fs-visually-hidden;
    transform: translate(-50%, -100%);// center horizontally
  }
}

