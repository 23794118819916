// -------------------------------------------------------------------------
// Layout Vars
//------------------------------------------------------------------------
$layout-max-width: 1050px;
$layout-max-width-narrow: 680px;
$layout-max-width-wide: 1200px;

$layout-small-bp: 480px;// used to adjust styles for phones (e.g. increase contrast)

// Header
$layout-header-bp: 960px;
$layout-header-height-mobile: 63px;
$layout-header-height-desktop: 81px;

// Homepage hero
$layout-homepage-hero-row: 1024px;

// Article page
$layout-article-cols: 960px;

// Share links
$layout-share-link-size: 30px;

// Close button
$layout-close-button-size: 60px;
