//------------------------------------------------------------------------
// Article page
//------------------------------------------------------------------------
.Article {
  $row-bp: $layout-article-cols;
  $full-width-bp: 1200px;
  $primary-col-width: 56.25%;
  $share-top-bp: 1024px;

  @include fs-min-width($row-bp) {
    display: flex;
    height: 100%;
  }

  &-primary {
    @include fs-scale(padding-top, ($row-bp: 40px, 1300px: 80px));
    background-color: #fff;
    position: relative;

    @include fs-min-width($row-bp) {
      flex: 1 0 $primary-col-width;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;// iOS “momentum” scrolling
      scroll-behavior: smooth;// native smooth scrolling for jump links
    }
  }// end primary

  &-content {
    margin-left: auto;
    margin-right: auto;
    max-width: fs-rem(650px);
    padding-bottom: fs-rem(100px);

    @include fs-min-width($row-bp) {
      max-width: fs-rem(600px);
    }
  }// end content

  &-title {
    margin-bottom: fs-rem(24px);
  }// end title

  &-share {
    margin-bottom: fs-rem(30px);
  }// end share

  .Share {
    position: relative;

    @include fs-min-width($share-top-bp) {
      position: absolute;
      right: fs-rem(36px);
      top: fs-rem(30px);
    }

    &-list {
      @include fs-max-width($share-top-bp - 1px) {
        display: flex;
      }
    }// end list

    &-item {
      @include fs-max-width($share-top-bp - 1px) {
        margin-right: fs-rem(15px);
      }
    }// end item

    &-link {
      $size: 38px;
      color: $c-default;
      opacity: 0.3;
      height: fs-rem($size);
      width: fs-rem($size);
    }// end link
  }// end share

  &-description {
    margin-bottom: fs-rem(30px);
  }// end description

  &-body {
    &-heading {
      margin-bottom: fs-rem(12px);
    }// end heading

    &-description {
      margin-bottom: fs-rem(40px);

      &:last-child {
        margin-bottom: 0;
      }
    }// end description
  }// end body

  &-secondary {
    position: relative;

    @include fs-max-width($row-bp - 1px) {
      display: none;
    }

    @include fs-min-width($row-bp) {
      flex: 1 1 #{100% - $primary-col-width};
      overflow: hidden;// hide scrollbars in Windows
    }
  }// end secondary

  &-background {
    overflow: hidden;
    z-index: 1;

    @include fs-min-width($row-bp) {
      @include fs-absolute-fill;

      img {
        @include fs-absolute-fill;
        object-fit: cover;
      }
    }
  }// end background

}// end Article
