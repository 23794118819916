// -------------------------------------------------------------------------
// Link Styles
// -------------------------------------------------------------------------
// Base link styles applied to all <a> tags.
// Only include the bare minimum, all other styles should be separate mixins.
@mixin link-base {
  text-decoration: none;
  transition: all 150ms ease-in-out;

  @include fs-attention {
    text-decoration: underline;
  }
}

// Define custom link styles below
//---------------------------------
@mixin link-green() {
  @include fs-link-colors(
    $static: fs-color('text', 'green'),
    $attention: darken(fs-color('text', 'green'), 10%)
  );
}

@mixin link-black() {
  @include fs-all-states {
    color: $c-default;
  }

  @include fs-attention {
    text-decoration: underline;
  }
}

// Custom named link styles
//---------------------------------
@mixin link-default() {
  @include link-green;
}

@mixin link-richtext() {
  @include link-green;
  font-weight: bold;

  .icon,
  .external-icon {
    display: inline-block;
    fill: currentColor;
    margin-left: fs-rem(5px);
  }
}
